import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";
import { User } from "./interfaces/User";
import { Request } from "./interfaces/Request";
import { getCurrentUser } from "./features/user-feature";

const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });

const AsyncProjectContainer = loadable({ loader: () => import(/* webpackChunkName: "Projects" */ "./containers/project/ProjectContainer"), loading: Loading });
const AsyncProjectEdit = loadable({ loader: () => import(/* webpackChunkName: "ProjectEdit" */ "./containers/project/ProjectEdit"), loading: Loading });
const AsyncProjectChecklist = loadable({ loader: () => import(/* webpackChunkName: "ProjectEdit" */ "./containers/project/ProjectChecklist"), loading: Loading });
const AsyncChecklistContainer = loadable({ loader: () => import(/* webpackChunkName: "Checklists" */ "./containers/checklist/ChecklistContainer"), loading: Loading });
const AsyncChecklistEdit = loadable({ loader: () => import(/* webpackChunkName: "Checklists" */ "./containers/checklist/ChecklistAdd"), loading: Loading });
const AsyncDocumentContainer = loadable({ loader: () => import(/* webpackChunkName: "Documents" */ "./containers/document/DocumentContainer"), loading: Loading });
/*const AsyncDocumentAdd = loadable({ loader: () => import(*//* webpackChunkName: "DocumentAdd" *//* "./containers/document/DocumentAdd"), loading: Loading });*/
const AsyncUserContainer = loadable({ loader: () => import(/* webpackChunkName: "UserManagement" */ "./containers/user/UserContainer"), loading: Loading });
const AsyncUserEdit = loadable({ loader: () => import(/* webpackChunkName: "UserEdit" */ "./containers/user/UserEdit"), loading: Loading });
const AsyncUserAdd = loadable({ loader: () => import(/* webpackChunkName: "UserAdd" */ "./containers/user/UserAdd"), loading: Loading });
const AsyncSettings = loadable({ loader: () => import(/* webpackChunkName: "UserAdd" */ "./containers/settings/Settings"), loading: Loading });

const Routes: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && (user.roleName?.toLowerCase() === "administrator" || user.roleName?.toLowerCase() === "projektleiter")} exact path="/projects" render={() => <AsyncProjectContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && (user.roleName?.toLowerCase() === "administrator" || user.roleName?.toLowerCase() === "projektleiter")} exact path="/project-edit/:id" render={() => <AsyncProjectEdit />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && (user.roleName?.toLowerCase() === "administrator" || user.roleName?.toLowerCase() === "projektleiter")} exact path="/project-add" render={() => <AsyncProjectEdit />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && (user.roleName?.toLowerCase() === "administrator" || user.roleName?.toLowerCase() === "projektleiter")} exact path="/project-checklist/:id" render={() => <AsyncProjectChecklist />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/checklists/" render={() => <AsyncChecklistContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/checklist-add/" render={() => <AsyncChecklistEdit />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/checklist-edit/:id" render={() => <AsyncChecklistEdit />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/documents" render={() => <AsyncDocumentContainer />} />
{/*            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/document-add" render={() => <AsyncDocumentAdd />} />*/}
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/users" render={() => <AsyncUserContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/user-edit/:id" render={() => <AsyncUserEdit />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/user-add" render={() => <AsyncUserAdd />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/settings" render={() => <AsyncSettings />} />

            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/" render={() => <AsyncProjectContainer />} />
        </Switch>
    );
}

export default Routes;