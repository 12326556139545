import { AppSettings } from "./interfaces/App-Settings";

const appSettings: AppSettings = {


    //apiPath: "https://localhost:44361",
    //apiPath: "http://api.dev-baltensperger.chili.ch",

    //Prod          
    apiPath: "https://api-baltiapp.baltensperger.ch",

    //redirect: "http://admin.dev-baltensperger.chili.ch/",
    redirect: "https://admin-baltiapp.baltensperger.ch",
    //redirect: "https://localhost:44361/",

    //redirectApp: "http://localhost:8081"
    redirectApp: "https://app-baltiapp.baltensperger.ch"
}

export default appSettings;