import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Navigation from "../navigation/Navigation";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AppState } from "../../interfaces/app-state";
import { User } from "../../interfaces/User";

export const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#d6d6d6",
        color: "#fff",
        overflow: "hidden"
    },
    title: {
      flexGrow: 1,
      lineHeight: 1,
      color: "black"
    },
    link: {
      color: "black",
      textDecoration: "none",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const user = useSelector<AppState, User>((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="profileMenu"
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        "style" :{
          backgroundColor: "rgba(12,39,47,0.4)",
          color: "#fff"
        }
      }}
    >
      <MenuItem
        component={RouterLink} to="/logout" onClick={handleMenuClose}>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <div className={classes.drawer}>
        <Hidden lgUp implementation="css">
          <SwipeableDrawer
            anchor={"left"}
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </Drawer>
        </Hidden>
      </div>
      <div className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <RouterLink to="/" className={classes.link}>

            </RouterLink>
          </Typography>
          {user && user.id ? (
            <MenuItem onClick={handleMenuOpen}>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <p>
                {user.firstName} {user.lastName}
              </p>
            </MenuItem>
          ) : (
              <Button color="inherit" component={RouterLink} to="/login">
                Login
              </Button>
            )}
        </Toolbar>
      </div>
      {renderMenu}
    </div>
  );
};

export default Header;
