import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// icons
import PeopleIcon from "@material-ui/icons/People";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExtensionIcon from '@material-ui/icons/Extension';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import SettingsIcon from '@material-ui/icons/Settings';

import { NavLink as RouterLink } from "react-router-dom";
import ProtectedContent from "../protectedContent/protectedContent";
import ProtectedContentPL from "../protectedContent/ProtectedContentPL";

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: 240,
        },
        toolbar: theme.mixins.toolbar,
        logo: {
            width: "50%",
            marginTop: "18px",
            marginBottom: "18px",
            marginLeft: "50%",
            transform: "translateX(-50%)"
        },
        link: {
            color: "#000",
            width: "calc(100% + 10px)",
            "&:hover, &:focus": {
              backgroundColor: "rgba(12,39,47,0.6)",
            },
            "& .MuiListItemIcon-root": {
              color: "#000",
            },
        },
        activeLink: {
            backgroundColor: "rgba(12,39,47,0.4)",
            width: "calc(100% + 10px)",
            "&:hover, &:focus": {
              backgroundColor: "rgba(12,39,47,0.4)",
            },
        },
    })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/assets/img/logo.png" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ProtectedContentPL>
          <ListItem
            button
            component={RouterLink}
            to="/projects"
            exact
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
                <ExtensionIcon />
            </ListItemIcon>
            <ListItemText primary={"Projekte"} />
          </ListItem>
        </ProtectedContentPL>
        <ProtectedContent>
          <ListItem
            button
            component={RouterLink}
            to="/checklists"
            exact
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
                <SquareFootIcon />
            </ListItemIcon>
            <ListItemText primary={"Checklisten"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent>
          <ListItem
            button
            component={RouterLink}
            to="/documents"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
                <PictureAsPdfIcon />
            </ListItemIcon>
            <ListItemText primary={"Dokumente"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent>
            <ListItem
                button
                component={RouterLink}
                to="/users"
                className={classes.link}
                activeClassName={classes.activeLink}
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={"Benutzer"} />
            </ListItem>
              </ProtectedContent>

        <ProtectedContent>
                  <ListItem
                      button
                      component={RouterLink}
                      to="/settings"
                      className={classes.link}
                      activeClassName={classes.activeLink}
                  >
                      <ListItemIcon>
                          <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Einstellungen"} />
                  </ListItem>
              </ProtectedContent>
      </List>
          <label style={{bottom:"0", position:"absolute"}}>Version 1.0</label>
    </div>
  );
};

export default Navigation;
